<template>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="info" top>
        {{ text }}
        <v-btn text @click="snackbar = false">关闭</v-btn>
    </v-snackbar>
</template>

<script>
export default {
    data() {
        return {
            snackbar: false,
            text: "",
            timeout: 5000,
        };
    },
    created() {
        this.$root.$on("show-snackbar", this.showSnackbar);
    },
    beforeDestroy() {
        this.$root.$off("show-snackbar", this.showSnackbar);
    },
    methods: {
        showSnackbar(payload) {
            this.text = payload.text || "";
            this.timeout = payload.timeout || 5000;
            this.snackbar = true;
        },

        // 使用方法
        demo() {
            this.$root.$emit("show-snackbar", {
                text: "这是一个 Snackbar 消息",
                timeout: 20000, // 可选，以毫秒为单位
            });
        },
    },
};
</script>

