// http.js
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api-bigbear-v2.sodtool.com',
  // baseURL: 'http://127.0.0.1:8188',
  timeout: 30000, // 请求超时时间（毫秒）(30秒)
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': 'zh',
  },
});

// 请求拦截器
instance.interceptors.request.use(
  config => {
    // 在这里添加任何请求前的逻辑，例如设置认证头
    const token = localStorage.getItem('token');
    if (token) {
      // config.headers.token = token;
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 响应拦截器
instance.interceptors.response.use(
  response => {
    // 在这里添加任何响应后的逻辑，例如处理错误状态
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
