<template>
    <div>
        <v-card outlined shaped elevation="3" :loading="isUserInfoCardLoading">
            <v-card-title>账号概览</v-card-title>
            <v-card-text>
                <div>
                    <span class="pr-3"><strong>邮箱</strong></span>
                    <span v-text="email"></span>
                </div>
                <div>
                    <span class="pr-3"><strong>订阅状态</strong></span>
                    <span v-text="subscriptionStatus"></span>
                </div>
                <div>
                    <span class="pr-3"><strong>过期时间</strong></span>
                    <span v-text="expiration"></span>
                </div>
                <div>
                    <span class="pr-3"><strong>剩余流量</strong></span>
                    <span>
                        <span v-text="remainingNetworkTraffic"></span>
                    </span>
                </div>
                <div>
                    <span class="pr-3"><strong>流量重置日期</strong></span>
                    <span>购买日</span>
                </div>
            </v-card-text>
        </v-card>

        <v-card outlined elevation="3" class="mt-5" v-show="!isVIP">
            <v-card-title> 只需三步，即可无限制浏览网络！ </v-card-title>
            <v-card-text>
                <v-timeline>
                    <v-timeline-item>
                        <template v-slot:opposite>
                            <span>第一步</span>
                        </template>
                        <v-btn color="info" link to="/shop"> 订阅会员 </v-btn>
                    </v-timeline-item>
                </v-timeline>
                <v-timeline>
                    <v-timeline-item>
                        <template v-slot:opposite>
                            <span>第二步</span>
                        </template>
                        <v-card class="elevation-2">
                            <v-card-title class="text-h6">
                                下载客户端
                            </v-card-title>
                            <v-card-text>
                                根据不同的设备下载客户端
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
                <v-timeline>
                    <v-timeline-item>
                        <template v-slot:opposite>
                            <span>第三步</span>
                        </template>
                        <v-card class="elevation-2">
                            <v-card-title class="text-h6">
                                下载大熊VPN App登录会员账号即可使用)<br>
                                (或复制订阅链接, 将节点导入到客户端 
                            </v-card-title>
                            <v-card-text>
                                开始无限制浏览网络！
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>
            </v-card-text>
        </v-card>

        <v-card outlined elevation="3" class="mt-5">
            <v-card-title>
                订阅详情
            </v-card-title>

            <v-card-text>
                <div>
                    <v-btn text outlined class="ml-2  mt-2 wrap-button" href="https://video.bigbear01.site" target="_blank"><v-icon dense></v-icon>🔗视频使用教程</v-btn>

                </div>

                <div>
                    <v-btn text outlined class="ml-2  mt-2 wrap-button" href="https://resource.bigbear01.site" target="_blank"><v-icon dense>$vuetify.icons.android</v-icon>大熊安卓App下載</v-btn>
                    <v-btn text outlined class="ml-2  mt-2 wrap-button" href="https://resource.bigbear01.site/static/bigbear-win.zip"><v-icon dense>$vuetify.icons.windows</v-icon>大熊Windows App下載</v-btn>
                    <v-btn text outlined class="ml-2 mt-2" href="https://apps.apple.com/app/id1594060308" target="_blank"><v-icon dense>$vuetify.icons.ios</v-icon>大熊Mac, iPhone下载<br>(需海外App Store)</v-btn>
                </div>
                <div>
                    <v-btn text outlined class="ml-2 mt-2" href="https://wiki-bigbearvpn.sodtool.com/zh/use/v2rayng.html" target="_blank"><v-icon dense>$vuetify.icons.android</v-icon> V2rayNG(Android)使用教程</v-btn>
                    <v-btn text outlined class="ml-2 mt-2" href="https://wiki-bigbearvpn.sodtool.com/zh/use/clash_for_android.html" target="_blank"><v-icon dense>$vuetify.icons.android</v-icon> Clash(Android)使用教程</v-btn>
                    <v-btn text outlined class="ml-2 mt-2" href="https://wiki-bigbearvpn.sodtool.com/zh/use/v2rayn.html" target="_blank"><v-icon dense>$vuetify.icons.windows</v-icon> V2rayN(Windows)使用教程</v-btn>
                    <v-btn text outlined class="ml-2 mt-2" href="https://wiki-bigbearvpn.sodtool.com/zh/use/clash_verge.html" target="_blank"><v-icon dense>$vuetify.icons.mac</v-icon><v-icon dense>$vuetify.icons.windows</v-icon>Clash Verge(Mac&Windows)使用教程</v-btn>
                    <v-btn text outlined class="ml-2  mt-2 wrap-button" href="https://wiki-bigbearvpn.sodtool.com/zh/use/shadowrocket.html" target="_blank"><v-icon dense>$vuetify.icons.ios</v-icon>Shadowrocket<br>(iPhone)使用教程</v-btn>
                </div>

                <div class="mt-2">
                    <v-btn text outlined class="ml-2 mt-2" @click="showSubDialog('clash')">🔗复制Clash订阅链接</v-btn>
                    <v-btn text outlined class="ml-2 mt-2" @click="showSubDialog('xray')">🔗复制Xray订阅链接</v-btn>
                </div>

                <div class="mt-2">
                    <v-btn :loading="btnLoading" text outlined class="ml-2 mt-2" @click="showShadowrocketAccountDialog()">查看Shadowrocket账号</v-btn>
                </div>

                <div class="mt-2">
                    <v-btn text outlined class="ml-2 mt-2" href="https://t.me/bigbearvpngroup" target="_blank">加入Telegram 讨论组</v-btn>
                </div>


            </v-card-text>
        </v-card>


        <v-card outlined elevation="3" class="mt-5">
            <v-card-title>
                技术支持
            </v-card-title>

            <v-card-text>
                <div>
                    <span class="pr-3"><strong>QQ:</strong></span>
                    <span>213481371</span>
                </div>
                <div>
                    <span class="pr-3"><strong>Telegram:</strong></span>
                    <span><a href="https://t.me/BigBearVPN" target="_blank">@BigBearVPN</a></span>
                </div>
            </v-card-text>
        </v-card>

		<v-dialog v-model="copySubDialog" max-width="600">
			<v-card>
				<v-card-title class="headline">订阅链接</v-card-title>
				<v-card-text>
					{{subUrl}}
				</v-card-text>
				<v-card-actions>
				<v-spacer></v-spacer>
                <v-btn color="info darken-1" text @click="copySubUrl">复制</v-btn>
				<v-btn color="green darken-1" text @click="copySubDialog = false">关闭</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


        <v-dialog v-model="shadowrocketAccountDialog" max-width="600">
			<v-card>
				<!-- <v-card-title class="headline">shadowrocket账号信息</v-card-title> -->
                <v-card-title>
                    <p class="warning">
                    共享Apple ID登录前必看（非常重要）:
                    </p>
                    <br>
                    <p>
                        <span>1. 点击App Store, </span>
                        <span class="warning">点击右上角头像</span> 
                        <span class="warning">下滑到底部，点击退出登录。</span>
                        <span class="info">（不要在设置里登录iCloud）</span>
                        
                    </p>
                    <br>
                    <p>
                        <span>2. 输入账号密码后，</span>
                        <span class="warning">点击其它选项，再点击不升级！！！    </span>
                    </p>
                    <br>
                    <p class="accent">⚠️此账号为共享ID，基本上每个月会被封，每个月总有一两个人不看说明被锁机，登录iCloud手机变砖本平台概不负责</p>
                    <p class="accent">⚠️重要！！！登录不需要绑定手机号，别人登录会一直发短信给你</p>
                    <p class="accent">⚠️重要！！！在设置里登录iCloud，手机变砖后果自负</p>
                </v-card-title>
				<v-card-text>
                    <div>
                        <span class="pr-3"><strong>账号</strong></span>
                        <span>{{ appleIdEmail }}</span>
                    </div>

                    <div>
                        <span class="pr-3"><strong>密码</strong></span>
                        <span>{{ appleIdPassword }}</span>
                    </div>
				</v-card-text>
				<v-card-actions>
				<v-spacer></v-spacer>
                <v-btn color="info darken-1" text @click="copyAppleId('email')">复制账号</v-btn>
                <v-btn color="info darken-1" text @click="copyAppleId('password')">复制密码</v-btn>
				<v-btn color="green darken-1" text @click="shadowrocketAccountDialog = false">关闭</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

    </div>
</template>

<style>
	.v-tab,
	.v-btn {
		text-transform: none;
	}
    .wrap-button {
        max-width: 100%; /* 或者您希望的最大宽度 */
        white-space: normal !important;
        word-break: break-word !important;
    }
</style>

<script>
import http from '../api/http';
export default {
    data: () => ({
        copySubDialog: false,
        shadowrocketAccountDialog: false,
        btnLoading: false,
        subUrl: "",
        appleIdEmail: "",
        appleIdPassword: "",
        isUserInfoCardLoading: false,
        email: "",
        subscriptionStatus: "未订阅",
        expiration: "已过期",
        remainingNetworkTraffic: 0,
        isVIP: false,
        uuid: '',
        tab: null,
        isShowToast: false,
        toastMsg: '',
    }),
    mounted: async function () {
        this.isUserInfoCardLoading = true;

        try {
            const response = await http.get('/user/info')
            this.isUserInfoCardLoading = false
            let data = response.data.data
            this.email = localStorage.getItem('email') || "None"            

            let isVIP = data.is_vip
    
            if (isVIP == false) {
                return
            }

            this.isVIP = true
            this.subscriptionStatus = "已订阅"
            this.uuid = data.uuid
            this.expiration = data.vip_end_time

            let traffic = data.mb

            if (traffic < 1024) {
                this.remainingNetworkTraffic = traffic + ' MB'
            } else {
                this.remainingNetworkTraffic = (traffic / 1024).toFixed(2) + ' GB'
            }

        } catch (error) {
            this.isUserInfoCardLoading = false
            this.$root.$emit('show-snackbar', {
                text: error.response.data.msg
            })
            localStorage.removeItem('token')
            this.$router.push( { path: '/login' } )
        }
    },
	methods: {
        isVIPFnc() {
            if (this.isVIP == false) {
                this.$root.$emit("show-snackbar", {
                    text: "请先订阅VIP",
                })

                return false
            }

            return true
        },
        showSubDialog(subType) {
            if (!this.isVIPFnc()) return
            this.copySubDialog = true
            this.subUrl = `https://api-bigbear-v2-sub.sodtool.com/subscribe?uuid=${this.uuid}&type=${subType}`

            // if (subType == "xray") {
            //     this.subUrl = `https://api-bigbear-v2-sub.sodtool.com/subscribe?uuid=${this.uuid}&type=${subType}`
            // } else {
            //     this.subUrl = `https://api-bigbear.sodtool.com/users/subscribe?uuid=${this.uuid}&subscription_type=${subType}`
            // }
        },
        copySubUrl() {
            navigator.clipboard.writeText(this.subUrl).then(() => {
                this.$root.$emit("show-snackbar", {
                    text: "复制成功",
                })

            },() => {
                this.$root.$emit("show-snackbar", {
                    text: "复制失败, 请手动复制",
                })
            })
        },

        async showShadowrocketAccountDialog() {
            if (!this.isVIPFnc()) return
            this.btnLoading = true
            
            try {
                const response = await http.get('/appleid/get')

                let data = response.data.data
                this.appleIdEmail = data.email
                this.appleIdPassword = data.password
                this.shadowrocketAccountDialog = true

            } catch (error) {
                console.log('请求失败:', error);

                this.$root.$emit("show-snackbar", {
                    text: error.response.data.msg,
                })
            }

            this.btnLoading = false

        },
        copyAppleId(type) {
            var text = ""
            if (type == 'email') {
                text = this.appleIdEmail
            } else {
                text = this.appleIdPassword
            }

            navigator.clipboard.writeText(text).then(() => {
                this.$root.$emit("show-snackbar", {
                    text: "复制成功",
                })

            },() => {
                this.$root.$emit("show-snackbar", {
                    text: "复制失败, 请手动复制",
                })
            })
        },

		importConfigUrl() {
            if (!this.isVIPFnc()) return
            let url = 'clash://install-config?url=' + 'https://api-bigbear-speed.sodtool.com/nodes/cat/' + this.uuid
            window.open(url)
        }

	}
};
</script>