import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const icons = {
	i18n: 'iconfont icon-i18n',
	overview: 'iconfont icon-overview',
	shop: 'iconfont icon-shop',
	order: 'iconfont icon-order',
	alipay: 'iconfont icon-alipay',
	wechat_pay: 'iconfont icon-wechat-pay',
	bigbearvpn: 'iconfont icon-bigbearvpn',
	menu_close: 'iconfont icon-menu_close',
	menu_open: 'iconfont icon-menu_open',
	sun: 'iconfont icon-sun',
	moon: 'iconfont icon-moon',
	my: 'iconfont icon-my',
	ios: 'iconfont icon-ios',
	mac: 'iconfont icon-mac',
	android: 'iconfont icon-android',
	windows: 'iconfont icon-windows',
	arrow_top: 'iconfont icon-arrow_top',
	previous_page: 'iconfont icon-previous_page',
	next_page: 'iconfont icon-next_page',
	drop_down: 'iconfont icon-drop_down',
	telegram: 'iconfont icon-telegram',
	back: 'iconfont icon-back',
	close: 'iconfont icon-close',
	text: 'iconfont icon-text',
	qrcode: 'iconfont icon-qrcode',
	wiki: 'iconfont icon-wiki',
}


export default new Vuetify({
	icons: {
		values: icons
	},

});
