import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../components/Layout.vue'
import Overview from '../views/Overview.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/Home.vue'),
        hidden: true
    },
    {
        path: '/login',
        name: '登录',
        component: () => import('../views/Login.vue'),
        hidden: true
    },
    {
        path: '/register',
        name: '注册',
        component: () => import('../views/Register.vue'),
        hidden: true
    },
    {
        path: '/update-password',
        name: '找回密码',
        component: () => import('../views/UpdatePassword.vue'),
        hidden: true
    },
    {
        path: '/overview',
        component: Layout,
        children: [
            { path: '', icon: 'overview', name: '概览', component: Overview },
        ]
    },
    {
        path: '/shop',
        component: Layout,
        children: [
            { path: '', icon: 'shop', name: '商店', component: () => import('../views/Shop.vue') },
        ]
    },
    {
        path: '/orders',
        component: Layout,
        children: [
            { path: '', icon: 'order', name: '订单列表', component: () => import('../views/Orders.vue') },
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const openPaths = ['/', '/login', '/register', '/update-password']

// Function to check if the site is opened in a common messaging or social app
function isInApp() {
    const ua = navigator.userAgent || navigator.vendor || window.opera;
    return (/FBAN|FBAV|Instagram|Snapchat|Twitter|Line|wechat|MicroMessenger|QQ|Telegram|TelegramBot/i.test(ua));
}

// Add a global navigation guard
router.beforeEach((to, from, next) => {
    if (isInApp()) {
        alert('请在浏览器中打开此页面');
        return;
    }

    const token = localStorage.getItem('token')
    
    if (!openPaths.includes(to.path) && !token) {
        next('/login')
    } else {
        next()
    }
})

export default router
