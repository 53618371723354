<template>
    <v-app>
        <v-main>
            <router-view />
        </v-main>
        <Snackbar />
    </v-app>
</template>

<script>
import Snackbar from '@/components/Snackbar.vue';

export default {
    name: "App",
    components: {
        Snackbar,
    },
    data: () => ({
        //
    }),
    mounted() {
        var isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

        if (isDark == true) {
            this.$vuetify.theme.dark = true;
        } else {
            this.$vuetify.theme.dark = false;
        }

        let listeners = {
            dark: (mediaQueryList) => {
                if (mediaQueryList.matches) {
                    this.$vuetify.theme.dark = true;
                }
            },
            light: (mediaQueryList) => {
                if (mediaQueryList.matches) {
                    this.$vuetify.theme.dark = false;
                }
            },
        };

        window
            .matchMedia("(prefers-color-scheme: dark)")
            .addListener(listeners.dark);
        window
            .matchMedia("(prefers-color-scheme: light)")
            .addListener(listeners.light);
    },
};
</script>