<template>
	<v-app>
		<!-- 侧边栏 -->
		<v-navigation-drawer app v-model="drawer">
			<div @click="backHome" class="logo pl-6">
				<span><v-icon>$vuetify.icons.bigbearvpn</v-icon></span>
				<span class="ml-6 text-subtitle-1">BigBear</span>
			</div>

			<v-list nav>
				<v-list-item-group color="primary" v-for="(menu,index) in $router.options.routes" :key='index'>
					<v-list-item v-if="!menu.hidden" :to='menu.path'>
						<v-list-item-icon>
							<v-icon dense>$vuetify.icons.{{ menu.children[0].icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ menu.children[0].name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		<!-- 头部 -->
		<v-app-bar app :class="{ bar: isTransition }">
			<v-btn icon v-show="drawer" @click="drawer = !drawer,isTransition = false"><v-icon>$vuetify.icons.menu_close</v-icon></v-btn>
			<v-btn icon v-show="!drawer" @click="drawer = !drawer,isTransition = false"><v-icon>$vuetify.icons.menu_open</v-icon></v-btn>
			
			<div class="right">
				<v-btn class="ma-2" icon v-show="this.$vuetify.theme.dark"><v-icon @click="changeTheme()">$vuetify.icons.sun</v-icon></v-btn>
				<v-btn class="ma-2" icon v-show="!this.$vuetify.theme.dark"><v-icon @click="changeTheme()">$vuetify.icons.moon</v-icon></v-btn>
				
				<v-menu offset-y open-on-hover>
					<template v-slot:activator="{ on, attrs }">
						<v-btn text v-bind="attrs" v-on="on"><v-icon>$vuetify.icons.my</v-icon></v-btn>
					</template>
					<v-list>
						<v-list-item link>
							<v-list-item-title @click="updatePassword()">修改密码</v-list-item-title>
						</v-list-item>

						<v-list-item link>
							<v-list-item-title @click="logout">退出登录</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</v-app-bar>

		
		<!-- 内容区 -->
		<v-main style="padding: 20px;">
			<v-container fluid>
				<router-view></router-view>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
	export default {
		data: () => ({
			drawer: null,
			isTransition: false
		}),
		methods:{
			logout() {
				localStorage.clear()
				this.$router.push( { path: '/login' } )
			},
			changeTheme() {
				this.isTransition = true			
				this.$vuetify.theme.dark = !this.$vuetify.theme.dark
			},
			backHome() {
				this.$router.push( { path: '/' } )
			},
			updatePassword() {
				window.open(this.$router.resolve({ path: '/update-password' }).href, '_blank');
				// this.$router.push( { path: '/update-password' } )
			}
		}
	}
</script>

<style scoped>
	.logo{
		height: 60px;
		line-height: 60px;
		cursor: pointer;
		user-select:none;
	}
	.right{
		position: absolute;
		right: 30px;
	}
	.bar{
		transition: 0s;
	}
</style>
